:root {
  --black-a1: #0000000d;
  --black-a2: #0000001a;
  --black-a3: #00000026;
  --black-a4: #0003;
  --black-a5: #0000004d;
  --black-a6: #0006;
  --black-a7: #00000080;
  --black-a8: #0009;
  --black-a9: #000000b3;
  --black-a10: #000c;
  --black-a11: #000000e6;
  --black-a12: #000000f2;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root {
      --black-a1: #0000000d;
      --black-a2: #0000001a;
      --black-a3: #00000026;
      --black-a4: #0003;
      --black-a5: #0000004d;
      --black-a6: #0006;
      --black-a7: #00000080;
      --black-a8: #0009;
      --black-a9: #000000b3;
      --black-a10: #000c;
      --black-a11: #000000e6;
      --black-a12: #000000f2;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root {
        --black-a1: color(display-p3 0 0 0 / .05);
        --black-a2: color(display-p3 0 0 0 / .1);
        --black-a3: color(display-p3 0 0 0 / .15);
        --black-a4: color(display-p3 0 0 0 / .2);
        --black-a5: color(display-p3 0 0 0 / .3);
        --black-a6: color(display-p3 0 0 0 / .4);
        --black-a7: color(display-p3 0 0 0 / .5);
        --black-a8: color(display-p3 0 0 0 / .6);
        --black-a9: color(display-p3 0 0 0 / .7);
        --black-a10: color(display-p3 0 0 0 / .8);
        --black-a11: color(display-p3 0 0 0 / .9);
        --black-a12: color(display-p3 0 0 0 / .95);
      }
    }
  }
}

:root {
  --white-a2: #ffffff1a;
  --white-a3: #ffffff26;
  --white-a4: #fff3;
  --white-a5: #ffffff4d;
  --white-a6: #fff6;
  --white-a7: #ffffff80;
  --white-a8: #fff9;
  --white-a9: #ffffffb3;
  --white-a10: #fffc;
  --white-a11: #ffffffe6;
  --white-a12: #fffffff2;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root {
      --white-a1: #ffffff0d;
      --white-a2: #ffffff1a;
      --white-a3: #ffffff26;
      --white-a4: #fff3;
      --white-a5: #ffffff4d;
      --white-a6: #fff6;
      --white-a7: #ffffff80;
      --white-a8: #fff9;
      --white-a9: #ffffffb3;
      --white-a10: #fffc;
      --white-a11: #ffffffe6;
      --white-a12: #fffffff2;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root {
        --white-a1: color(display-p3 1 1 1 / .05);
        --white-a2: color(display-p3 1 1 1 / .1);
        --white-a3: color(display-p3 1 1 1 / .15);
        --white-a4: color(display-p3 1 1 1 / .2);
        --white-a5: color(display-p3 1 1 1 / .3);
        --white-a6: color(display-p3 1 1 1 / .4);
        --white-a7: color(display-p3 1 1 1 / .5);
        --white-a8: color(display-p3 1 1 1 / .6);
        --white-a9: color(display-p3 1 1 1 / .7);
        --white-a10: color(display-p3 1 1 1 / .8);
        --white-a11: color(display-p3 1 1 1 / .9);
        --white-a12: color(display-p3 1 1 1 / .95);
      }
    }
  }
}

:root, .light, .light-theme {
  --accent-blue-1: #fcfdff;
  --accent-blue-2: #f5f9ff;
  --accent-blue-3: #ebf2ff;
  --accent-blue-4: #ddebff;
  --accent-blue-5: #cde1ff;
  --accent-blue-6: #bad4fe;
  --accent-blue-7: #a1c2f7;
  --accent-blue-8: #7eaaf1;
  --accent-blue-9: #488dff;
  --accent-blue-10: #4081ed;
  --accent-blue-11: #2b6dd8;
  --accent-blue-12: #153261;
  --accent-blue-a1: #0055ff03;
  --accent-blue-a2: #0066ff0a;
  --accent-blue-a3: #005aff14;
  --accent-blue-a4: #0069ff22;
  --accent-blue-a5: #0066ff32;
  --accent-blue-a6: #0061fc45;
  --accent-blue-a7: #005aea5e;
  --accent-blue-a8: #0057e481;
  --accent-blue-a9: #0060ffb7;
  --accent-blue-a10: #0057e7bf;
  --accent-blue-a11: #004fd0d4;
  --accent-blue-a12: #002053ea;
  --accent-blue-contrast: #fff;
  --accent-blue-surface: #f3f8ffcc;
  --accent-blue-indicator: #488dff;
  --accent-blue-track: #488dff;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --accent-blue-1: #fcfdff;
      --accent-blue-2: #f6f9ff;
      --accent-blue-3: #ebf2fe;
      --accent-blue-4: #deebff;
      --accent-blue-5: #cee1ff;
      --accent-blue-6: #bad4fe;
      --accent-blue-7: #a1c2f7;
      --accent-blue-8: #7eaaf1;
      --accent-blue-9: #488dff;
      --accent-blue-10: #4081ed;
      --accent-blue-11: #2b6dd8;
      --accent-blue-12: #153261;
      --accent-blue-a1: #0062f603;
      --accent-blue-a2: #0060dc09;
      --accent-blue-a3: #005be913;
      --accent-blue-a4: #0063e71f;
      --accent-blue-a5: #005ce72e;
      --accent-blue-a6: #0059e440;
      --accent-blue-a7: #0054d258;
      --accent-blue-a8: #0051cd78;
      --accent-blue-a9: #0057eaa6;
      --accent-blue-a10: #004ed3af;
      --accent-blue-a11: #0046bcc2;
      --accent-blue-a12: #001a47e3;
      --accent-blue-contrast: #fff;
      --accent-blue-surface: #f3f8ffcc;
      --accent-blue-indicator: #488dff;
      --accent-blue-track: #488dff;
    }

    @supports (color: lab(0% 0 0)) {
      :root, .light, .light-theme {
        --accent-blue-1: lab(99.1788% -.163972 -1.1241);
        --accent-blue-2: lab(97.8835% -.475436 -3.36266);
        --accent-blue-3: lab(95.3002% -.898927 -6.69683);
        --accent-blue-4: lab(92.5646% -1.54868 -12.9228);
        --accent-blue-5: lab(88.8986% -1.93426 -18.3398);
        --accent-blue-6: lab(83.9444% -2.10014 -23.683);
        --accent-blue-7: lab(77.4459% -1.94272 -29.9999);
        --accent-blue-8: lab(68.4842% -.643909 -40.6953);
        --accent-blue-9: lab(58.5702% 7.06637 -64.1769);
        --accent-blue-10: lab(54.0801% 7.20602 -61.6869);
        --accent-blue-11: lab(46.4916% 9.72405 -62.2144);
        --accent-blue-12: lab(20.7037% 3.60723 -31.905);
        --accent-blue-indicator: lab(58.5702% 7.06637 -64.1769);
        --accent-blue-track: lab(58.5702% 7.06637 -64.1769);
      }
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --accent-blue-a1: color(display-p3 .024 .349 1 / .012);
        --accent-blue-a2: color(display-p3 .024 .349 .894 / .036);
        --accent-blue-a3: color(display-p3 .008 .322 .949 / .075);
        --accent-blue-a4: color(display-p3 .004 .361 .937 / .122);
        --accent-blue-a5: color(display-p3 .004 .329 .937 / .181);
        --accent-blue-a6: color(display-p3 .004 .314 .925 / .251);
        --accent-blue-a7: color(display-p3 .004 .298 .855 / .346);
        --accent-blue-a8: color(display-p3 .004 .286 .835 / .471);
        --accent-blue-a9: color(display-p3 0 .302 .953 / .651);
        --accent-blue-a10: color(display-p3 0 .271 .859 / .687);
        --accent-blue-a11: color(display-p3 0 .243 .765 / .761);
        --accent-blue-a12: color(display-p3 0 .094 .29 / .891);
        --accent-blue-surface: color(display-p3 .957 .973 1 / .8);
      }
    }
  }
}

:root, .light, .light-theme {
  --accent-green-1: #fbfefb;
  --accent-green-2: #f5fbf5;
  --accent-green-3: #e0fae1;
  --accent-green-4: #cdf6cf;
  --accent-green-5: #b9eebc;
  --accent-green-6: #a3e2a7;
  --accent-green-7: #87d18d;
  --accent-green-8: #5cbe68;
  --accent-green-9: #60e273;
  --accent-green-10: #4cd864;
  --accent-green-11: #2c823b;
  --accent-green-12: #214225;
  --accent-green-a1: #00c00004;
  --accent-green-a2: #0099000a;
  --accent-green-a3: #00d6091f;
  --accent-green-a4: #00d20b32;
  --accent-green-a5: #00c20b46;
  --accent-green-a6: #00af0c5c;
  --accent-green-a7: #009e0d78;
  --accent-green-a8: #009a13a3;
  --accent-green-a9: #00d11f9f;
  --accent-green-a10: #00c822b3;
  --accent-green-a11: #006812d3;
  --accent-green-a12: #002605de;
  --accent-green-contrast: #142716;
  --accent-green-surface: #f3faf3cc;
  --accent-green-indicator: #60e273;
  --accent-green-track: #60e273;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --accent-green-1: #fbfefb;
      --accent-green-2: #f5fbf5;
      --accent-green-3: #e0fae2;
      --accent-green-4: #cdf6cf;
      --accent-green-5: #b9eebc;
      --accent-green-6: #a3e2a7;
      --accent-green-7: #87d28d;
      --accent-green-8: #5cbe68;
      --accent-green-9: #60e273;
      --accent-green-10: #4bd864;
      --accent-green-11: #2d823b;
      --accent-green-12: #214225;
      --accent-green-a1: #00c10004;
      --accent-green-a2: #009c000a;
      --accent-green-a3: #00c4321c;
      --accent-green-a4: #00c2322d;
      --accent-green-a5: #00af2c3f;
      --accent-green-a6: #009c2653;
      --accent-green-a7: #008a216c;
      --accent-green-a8: #00831e8e;
      --accent-green-a9: #00bb2f80;
      --accent-green-a10: #00af2b8d;
      --accent-green-a11: #00510fbc;
      --accent-green-a12: #001d02d7;
      --accent-green-contrast: #142716;
      --accent-green-surface: #f3faf2cc;
      --accent-green-indicator: #60e273;
      --accent-green-track: #60e273;
    }

    @supports (color: lab(0% 0 0)) {
      :root, .light, .light-theme {
        --accent-green-1: lab(99.2548% -1.42044 1.04054);
        --accent-green-2: lab(98.0586% -3.12528 2.29224);
        --accent-green-3: lab(95.9126% -11.9416 8.81848);
        --accent-green-4: lab(93.0997% -19.0652 14.169);
        --accent-green-5: lab(89.4047% -24.7783 18.5274);
        --accent-green-6: lab(84.3784% -29.3643 22.0942);
        --accent-green-7: lab(77.8614% -34.5486 26.2298);
        --accent-green-8: lab(69.521% -43.8467 33.971);
        --accent-green-9: lab(80.8624% -54.5335 42.6003);
        --accent-green-10: lab(77.1251% -57.2138 45.1073);
        --accent-green-11: lab(48.0362% -38.5088 30.2871);
        --accent-green-12: lab(24.8165% -18.3088 14.0502);
        --accent-green-indicator: lab(80.8624% -54.5335 42.6003);
        --accent-green-track: lab(80.8624% -54.5335 42.6003);
      }
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --accent-green-a1: color(display-p3 .267 .757 .024 / .016);
        --accent-green-a2: color(display-p3 .122 .612 .024 / .04);
        --accent-green-a3: color(display-p3 .075 .788 .004 / .11);
        --accent-green-a4: color(display-p3 .071 .78 .004 / .177);
        --accent-green-a5: color(display-p3 .071 .702 .004 / .248);
        --accent-green-a6: color(display-p3 .051 .627 .004 / .326);
        --accent-green-a7: color(display-p3 .047 .557 .004 / .424);
        --accent-green-a8: color(display-p3 .031 .529 0 / .557);
        --accent-green-a9: color(display-p3 .039 .753 0 / .502);
        --accent-green-a10: color(display-p3 .031 .702 0 / .553);
        --accent-green-a11: color(display-p3 .012 .325 0 / .738);
        --accent-green-a12: color(display-p3 .004 .118 0 / .844);
        --accent-green-surface: color(display-p3 .957 .98 .953 / .8);
      }
    }
  }
}

:root, .light, .light-theme {
  --accent-1: #fafcff;
  --accent-2: #f5faff;
  --accent-3: #e9f1fe;
  --accent-4: #dde9fd;
  --accent-5: #d1e2fd;
  --accent-6: #c5dbfd;
  --accent-7: #b4cff9;
  --accent-8: #98bbf2;
  --accent-9: #081934;
  --accent-10: #1e314e;
  --accent-11: #456495;
  --accent-12: #0f213d;
  --accent-a1: #0066ff05;
  --accent-a2: #0080ff0a;
  --accent-a3: #005df416;
  --accent-a4: #005af022;
  --accent-a5: #005ff42e;
  --accent-a6: #0061f73a;
  --accent-a7: #015ceb4b;
  --accent-a8: #0057df67;
  --accent-a9: #00122df7;
  --accent-a10: #001636e1;
  --accent-a11: #002b6eba;
  --accent-a12: #001331f0;
  --accent-contrast: #fff;
  --accent-surface: #f3f9ffcc;
  --accent-indicator: #081934;
  --accent-track: #081934;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --accent-1: #fbfdff;
      --accent-2: #f6f9ff;
      --accent-3: #e9f1fe;
      --accent-4: #ddeafd;
      --accent-5: #d1e2fd;
      --accent-6: #c5dbfd;
      --accent-7: #b4cff9;
      --accent-8: #98bbf2;
      --accent-9: #081934;
      --accent-10: #1e314e;
      --accent-11: #456496;
      --accent-12: #0f213c;
      --accent-a1: #0070f705;
      --accent-a2: #0079de09;
      --accent-a3: #005edf15;
      --accent-a4: #0059d820;
      --accent-a5: #0056da2b;
      --accent-a6: #0059e036;
      --accent-a7: #0055d346;
      --accent-a8: #004fc960;
      --accent-a9: #000f27f3;
      --accent-a10: #00122fdd;
      --accent-a11: #00245fb3;
      --accent-a12: #00102aec;
      --accent-contrast: #fff;
      --accent-surface: #f2f8ffcc;
      --accent-indicator: #081934;
      --accent-track: #081934;
    }

    @supports (color: lab(0% 0 0)) {
      :root, .light, .light-theme {
        --accent-1: lab(99.0573% -.290692 -1.8677);
        --accent-2: lab(97.8754% -.671864 -4.4678);
        --accent-3: lab(94.8321% -1.06719 -7.41808);
        --accent-4: lab(92.0104% -1.49766 -11.0754);
        --accent-5: lab(89.2961% -1.88282 -15.0612);
        --accent-6: lab(86.4552% -2.18788 -19.3668);
        --accent-7: lab(82.2083% -2.34836 -23.9768);
        --accent-8: lab(74.877% -2.16186 -31.3115);
        --accent-9: lab(8.59674% 1.7865 -20.3154);
        --accent-10: lab(19.7153% .0677258 -20.5397);
        --accent-11: lab(41.8301% -.31136 -30.8835);
        --accent-12: lab(12.3514% 1.05072 -20.3957);
        --accent-indicator: lab(8.59674% 1.7865 -20.3154);
        --accent-track: lab(8.59674% 1.7865 -20.3154);
      }
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --accent-a1: color(display-p3 .02 .412 1 / .02);
        --accent-a2: color(display-p3 .024 .459 .894 / .036);
        --accent-a3: color(display-p3 .008 .341 .906 / .083);
        --accent-a4: color(display-p3 .008 .318 .878 / .126);
        --accent-a5: color(display-p3 .004 .306 .886 / .169);
        --accent-a6: color(display-p3 .004 .318 .91 / .212);
        --accent-a7: color(display-p3 .004 .302 .859 / .275);
        --accent-a8: color(display-p3 .004 .282 .816 / .377);
        --accent-a9: color(display-p3 0 .055 .157 / .953);
        --accent-a10: color(display-p3 0 .063 .192 / .867);
        --accent-a11: color(display-p3 0 .129 .388 / .702);
        --accent-a12: color(display-p3 0 .059 .169 / .926);
        --accent-surface: color(display-p3 .953 .973 1 / .8);
      }
    }
  }
}

:root, .light, .light-theme {
  --accent-red-1: #fffcfc;
  --accent-red-2: #fff7f7;
  --accent-red-3: #ffe9e8;
  --accent-red-4: #ffdad9;
  --accent-red-5: #ffcac8;
  --accent-red-6: #ffb5b4;
  --accent-red-7: #ff9d9d;
  --accent-red-8: #ff7e81;
  --accent-red-9: #e34b56;
  --accent-red-10: #d43c4a;
  --accent-red-11: #c34;
  --accent-red-12: #6c091a;
  --accent-red-a1: #ff000003;
  --accent-red-a2: #ff000008;
  --accent-red-a3: #ff0c0017;
  --accent-red-a4: #ff070026;
  --accent-red-a5: #ff0a0037;
  --accent-red-a6: #ff04014b;
  --accent-red-a7: #ff000062;
  --accent-red-a8: #ff000681;
  --accent-red-a9: #d80010b4;
  --accent-red-a10: #c70012c3;
  --accent-red-a11: #bf0015cc;
  --accent-red-a12: #670012f6;
  --accent-red-contrast: #fff;
  --accent-red-surface: #fff5f5cc;
  --accent-red-indicator: #e34b56;
  --accent-red-track: #e34b56;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --accent-red-1: #fffcfc;
      --accent-red-2: #fff8f7;
      --accent-red-3: #ffeae9;
      --accent-red-4: #ffdbda;
      --accent-red-5: #ffccca;
      --accent-red-6: #ffb8b7;
      --accent-red-7: #ffa09f;
      --accent-red-8: #ff7f81;
      --accent-red-9: #e34a56;
      --accent-red-10: #d43c4a;
      --accent-red-11: #c34;
      --accent-red-12: #6c0a1a;
      --accent-red-a1: #b8000003;
      --accent-red-a2: #ef000008;
      --accent-red-a3: #e2000017;
      --accent-red-a4: #e4000025;
      --accent-red-a5: #e8000036;
      --accent-red-a6: #e7000049;
      --accent-red-a7: #e8000060;
      --accent-red-a8: #ed00007b;
      --accent-red-a9: #c70000a9;
      --accent-red-a10: #b80000b7;
      --accent-red-a11: #b00002bf;
      --accent-red-a12: #5b0007ea;
      --accent-red-contrast: #fff;
      --accent-red-surface: #fff5f5cc;
      --accent-red-indicator: #e34a56;
      --accent-red-track: #e34a56;
    }

    @supports (color: lab(0% 0 0)) {
      :root, .light, .light-theme {
        --accent-red-1: lab(99.2642% 1.26496 .540423);
        --accent-red-2: lab(98.0344% 3.4802 1.48901);
        --accent-red-3: lab(94.4898% 9.18534 3.9462);
        --accent-red-4: lab(90.8867% 20.3139 8.80799);
        --accent-red-5: lab(86.8426% 27.0148 11.7949);
        --accent-red-6: lab(82.131% 32.7747 14.4156);
        --accent-red-7: lab(76.0984% 39.8354 17.7182);
        --accent-red-8: lab(68.5504% 50.4639 22.9339);
        --accent-red-9: lab(54.6356% 60.2162 28.5502);
        --accent-red-10: lab(49.872% 60.252 28.9229);
        --accent-red-11: lab(47.1993% 60.2734 29.1735);
        --accent-red-12: lab(22.4104% 41.4015 20.6355);
        --accent-red-indicator: lab(54.6356% 60.2162 28.5502);
        --accent-red-track: lab(54.6356% 60.2162 28.5502);
      }
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --accent-red-a1: color(display-p3 .675 .024 .024 / .012);
        --accent-red-a2: color(display-p3 .878 .02 .02 / .032);
        --accent-red-a3: color(display-p3 .831 .098 .012 / .091);
        --accent-red-a4: color(display-p3 .839 .035 .008 / .146);
        --accent-red-a5: color(display-p3 .855 .059 .004 / .212);
        --accent-red-a6: color(display-p3 .851 .031 .004 / .287);
        --accent-red-a7: color(display-p3 .855 .035 .004 / .377);
        --accent-red-a8: color(display-p3 .871 .012 .004 / .483);
        --accent-red-a9: color(display-p3 .733 0 .024 / .663);
        --accent-red-a10: color(display-p3 .678 0 .035 / .718);
        --accent-red-a11: color(display-p3 .647 0 .043 / .75);
        --accent-red-a12: color(display-p3 .333 0 .035 / .918);
        --accent-red-surface: color(display-p3 1 .961 .961 / .8);
      }
    }
  }
}

:root, .light, .light-theme, html {
  --color-background: #f2f2f2;
}

:root, .light, .light-theme {
  --gray-1: #fdfdfd;
  --gray-2: #f9f9f9;
  --gray-3: #f0f0f0;
  --gray-4: #e8e8e8;
  --gray-5: #e1e1e1;
  --gray-6: #d9d9d9;
  --gray-7: #cecece;
  --gray-8: #bbb;
  --gray-9: #8c8c8c;
  --gray-10: #828282;
  --gray-11: #626262;
  --gray-12: #202020;
  --gray-a1: #00000002;
  --gray-a2: #00000006;
  --gray-a3: #0000000f;
  --gray-a4: #00000017;
  --gray-a5: #0000001e;
  --gray-a6: #00000026;
  --gray-a7: #00000031;
  --gray-a8: #0004;
  --gray-a9: #00000073;
  --gray-a10: #0000007d;
  --gray-a11: #0000009d;
  --gray-a12: #000000df;
  --gray-contrast: #fff;
  --gray-surface: #fffc;
  --gray-indicator: #8c8c8c;
  --gray-track: #8c8c8c;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --gray-1: #fdfdfd;
      --gray-2: #f9f9f9;
      --gray-3: #f0f0f0;
      --gray-4: #e8e8e8;
      --gray-5: #e1e1e1;
      --gray-6: #d9d9d9;
      --gray-7: #cfcfcf;
      --gray-8: #bbb;
      --gray-9: #8c8c8c;
      --gray-10: #828282;
      --gray-11: #636363;
      --gray-12: #202020;
      --gray-a1: #00000002;
      --gray-a2: #00000006;
      --gray-a3: #0000000f;
      --gray-a4: #00000017;
      --gray-a5: #0000001e;
      --gray-a6: #00000026;
      --gray-a7: #00000031;
      --gray-a8: #0004;
      --gray-a9: #00000073;
      --gray-a10: #0000007d;
      --gray-a11: #0000009d;
      --gray-a12: #000000df;
      --gray-contrast: #fff;
      --gray-surface: #fffc;
      --gray-indicator: #8c8c8c;
      --gray-track: #8c8c8c;
    }

    @supports (color: lab(0% 0 0)) {
      :root, .light, .light-theme {
        --gray-1: lab(99.304% 0 -.0000119209);
        --gray-2: lab(97.912% 0 0);
        --gray-3: lab(94.896% 0 0);
        --gray-4: lab(92.112% -.0000298023 0);
        --gray-5: lab(89.56% -.0000298023 0);
        --gray-6: lab(86.776% -.0000298023 -.0000119209);
        --gray-7: lab(82.948% -.0000298023 0);
        --gray-8: lab(75.872% -.0000298023 .0000119209);
        --gray-9: lab(58.356% 0 0);
        --gray-10: lab(54.296% 0 0);
        --gray-11: lab(41.768% -.0000149012 -.00000596046);
        --gray-12: lab(12.188% 0 0);
        --gray-indicator: lab(58.356% 0 0);
        --gray-track: lab(58.356% 0 0);
      }
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --gray-a1: color(display-p3 0 0 0 / .008);
        --gray-a2: color(display-p3 0 0 0 / .024);
        --gray-a3: color(display-p3 0 0 0 / .059);
        --gray-a4: color(display-p3 0 0 0 / .09);
        --gray-a5: color(display-p3 0 0 0 / .118);
        --gray-a6: color(display-p3 0 0 0 / .149);
        --gray-a7: color(display-p3 0 0 0 / .192);
        --gray-a8: color(display-p3 0 0 0 / .267);
        --gray-a9: color(display-p3 0 0 0 / .451);
        --gray-a10: color(display-p3 0 0 0 / .49);
        --gray-a11: color(display-p3 0 0 0 / .616);
        --gray-a12: color(display-p3 0 0 0 / .875);
        --gray-surface: color(display-p3 1 1 1 / .8);
      }
    }
  }
}

.dark, .dark-theme {
  --accent-blue-1: #0b111c;
  --accent-blue-2: #111825;
  --accent-blue-3: #132646;
  --accent-blue-4: #123061;
  --accent-blue-5: #1b3c73;
  --accent-blue-6: #264a85;
  --accent-blue-7: #325a9b;
  --accent-blue-8: #3d6cba;
  --accent-blue-9: #488dff;
  --accent-blue-10: #3c80f1;
  --accent-blue-11: #84b4ff;
  --accent-blue-12: #cfe2ff;
  --accent-blue-a1: #0012fb0c;
  --accent-blue-a2: #1162f916;
  --accent-blue-a3: #1a6fff39;
  --accent-blue-a4: #146dff56;
  --accent-blue-a5: #2a7aff69;
  --accent-blue-a6: #3c85fd7d;
  --accent-blue-a7: #4a8fff94;
  --accent-blue-a8: #4f91feb6;
  --accent-blue-a9: #488dff;
  --accent-blue-a10: #3f87fff0;
  --accent-blue-a11: #84b4ff;
  --accent-blue-a12: #cfe2ff;
  --accent-blue-contrast: #fff;
  --accent-blue-surface: #111f3980;
  --accent-blue-indicator: #488dff;
  --accent-blue-track: #488dff;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    .dark, .dark-theme {
      --accent-blue-1: #0b111c;
      --accent-blue-2: #111825;
      --accent-blue-3: #132746;
      --accent-blue-4: #123061;
      --accent-blue-5: #1b3c73;
      --accent-blue-6: #264a85;
      --accent-blue-7: #325a9b;
      --accent-blue-8: #3d6cba;
      --accent-blue-9: #488dff;
      --accent-blue-10: #3c80f1;
      --accent-blue-11: #87b5ff;
      --accent-blue-12: #cfe2ff;
      --accent-blue-a1: #0032ef0b;
      --accent-blue-a2: #006eff14;
      --accent-blue-a3: #0877ff37;
      --accent-blue-a4: #1072ff53;
      --accent-blue-a5: #2c7fff65;
      --accent-blue-a6: #438bff78;
      --accent-blue-a7: #5195ff8f;
      --accent-blue-a8: #5697ffaf;
      --accent-blue-a9: #4f92fff7;
      --accent-blue-a10: #478cffe8;
      --accent-blue-a11: #8bbafff9;
      --accent-blue-a12: #d4e4fffc;
      --accent-blue-contrast: #fff;
      --accent-blue-surface: #0e1e3880;
      --accent-blue-indicator: #488dff;
      --accent-blue-track: #488dff;
    }

    @supports (color: lab(0% 0 0)) {
      .dark, .dark-theme {
        --accent-blue-1: lab(4.99911% -.220947 -7.77664);
        --accent-blue-2: lab(8.2097% -.368081 -10.0429);
        --accent-blue-3: lab(15.1956% 1.4279 -22.5539);
        --accent-blue-4: lab(19.9515% 4.24288 -32.9151);
        --accent-blue-5: lab(25.3561% 4.12993 -36.1082);
        --accent-blue-6: lab(31.2971% 3.29922 -37.6039);
        --accent-blue-7: lab(37.8809% 2.95022 -40.4806);
        --accent-blue-8: lab(45.2732% 3.60709 -46.7945);
        --accent-blue-9: lab(58.5702% 7.06637 -64.1769);
        --accent-blue-10: lab(53.9744% 8.26454 -64.079);
        --accent-blue-11: lab(72.4539% -.340521 -44.9028);
        --accent-blue-12: lab(89.1452% -1.86875 -17.2604);
        --accent-blue-indicator: lab(58.5702% 7.06637 -64.1769);
        --accent-blue-track: lab(58.5702% 7.06637 -64.1769);
      }
    }

    @supports (color: color(display-p3 0 0 0)) {
      .dark, .dark-theme {
        --accent-blue-a1: color(display-p3 0 .071 .98 / .043);
        --accent-blue-a2: color(display-p3 .118 .412 1 / .08);
        --accent-blue-a3: color(display-p3 .176 .451 1 / .215);
        --accent-blue-a4: color(display-p3 .176 .431 1 / .324);
        --accent-blue-a5: color(display-p3 .247 .486 1 / .395);
        --accent-blue-a6: color(display-p3 .318 .533 1 / .471);
        --accent-blue-a7: color(display-p3 .365 .573 1 / .559);
        --accent-blue-a8: color(display-p3 .384 .584 1 / .685);
        --accent-blue-a9: color(display-p3 .357 .561 .996 / .967);
        --accent-blue-a10: color(display-p3 .329 .537 .996 / .908);
        --accent-blue-a11: color(display-p3 .573 .722 1 / .975);
        --accent-blue-a12: color(display-p3 .839 .894 1 / .988);
        --accent-blue-surface: color(display-p3 .071 .118 .212 / .5);
      }
    }
  }
}

.dark, .dark-theme {
  --accent-1: #041027;
  --accent-2: #071832;
  --accent-3: #092551;
  --accent-4: #14315d;
  --accent-5: #213e6b;
  --accent-6: #2e4c7b;
  --accent-7: #3e5c8d;
  --accent-8: #5070a1;
  --accent-9: #6f90c4;
  --accent-10: #6384b7;
  --accent-11: #93b5eb;
  --accent-12: #cbe1ff;
  --accent-a1: #0007fb18;
  --accent-a2: #0043fb24;
  --accent-a3: #005bfe45;
  --accent-a4: #1a74fd52;
  --accent-a5: #3b87fd61;
  --accent-a6: #5295ff72;
  --accent-a7: #68a1ff85;
  --accent-a8: #78adfe9b;
  --accent-a9: #8ebaffc0;
  --accent-a10: #87b6ffb2;
  --accent-a11: #9fc4ffea;
  --accent-a12: #cbe1ff;
  --accent-contrast: #fff;
  --accent-surface: #001f5380;
  --accent-indicator: #6f90c4;
  --accent-track: #6f90c4;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    .dark, .dark-theme {
      --accent-1: #041027;
      --accent-2: #071732;
      --accent-3: #092551;
      --accent-4: #14305d;
      --accent-5: #203d6b;
      --accent-6: #2e4c7b;
      --accent-7: #3e5c8d;
      --accent-8: #5070a1;
      --accent-9: #6f90c4;
      --accent-10: #6384b7;
      --accent-11: #92b5eb;
      --accent-12: #c7e2ff;
      --accent-a1: #002ff116;
      --accent-a2: #0053f421;
      --accent-a3: #0064f542;
      --accent-a4: #157aff4e;
      --accent-a5: #418cff5d;
      --accent-a6: #599aff6d;
      --accent-a7: #6da6ff81;
      --accent-a8: #80b3ff96;
      --accent-a9: #95beffbc;
      --accent-a10: #8dbaffae;
      --accent-a11: #a5c8ffe4;
      --accent-a12: #cfe4fffc;
      --accent-contrast: #fff;
      --accent-surface: #001e4e80;
      --accent-indicator: #6f90c4;
      --accent-track: #6f90c4;
    }

    @supports (color: lab(0% 0 0)) {
      .dark, .dark-theme {
        --accent-1: lab(4.78702% 1.38716 -16.8627);
        --accent-2: lab(7.89157% 1.949 -20.2992);
        --accent-3: lab(14.8821% 4.30894 -30.3458);
        --accent-4: lab(19.9543% 2.89853 -30.4527);
        --accent-5: lab(25.5898% 1.73856 -30.5739);
        --accent-6: lab(31.6754% .800833 -30.6984);
        --accent-7: lab(38.5647% -.000566244 -30.8272);
        --accent-8: lab(46.2587% -.678629 -30.9552);
        --accent-9: lab(58.831% -1.47587 -31.1321);
        --accent-10: lab(54.1766% -1.21543 -31.0709);
        --accent-11: lab(72.6687% -2.0828 -31.2894);
        --accent-12: lab(88.8202% -2.58166 -31.4359);
        --accent-indicator: lab(58.831% -1.47587 -31.1321);
        --accent-track: lab(58.831% -1.47587 -31.1321);
      }
    }

    @supports (color: color(display-p3 0 0 0)) {
      .dark, .dark-theme {
        --accent-a1: color(display-p3 0 .024 .988 / .085);
        --accent-a2: color(display-p3 0 .278 .996 / .131);
        --accent-a3: color(display-p3 .067 .357 .996 / .257);
        --accent-a4: color(display-p3 .196 .463 1 / .307);
        --accent-a5: color(display-p3 .314 .537 1 / .366);
        --accent-a6: color(display-p3 .396 .596 1 / .429);
        --accent-a7: color(display-p3 .463 .643 1 / .505);
        --accent-a8: color(display-p3 .533 .694 1 / .589);
        --accent-a9: color(display-p3 .608 .741 1 / .736);
        --accent-a10: color(display-p3 .58 .725 1 / .681);
        --accent-a11: color(display-p3 .667 .78 1 / .895);
        --accent-a12: color(display-p3 .824 .89 1 / .988);
        --accent-surface: color(display-p3 .02 .11 .306 / .5);
      }
    }
  }
}

.dark, .dark-theme {
  --accent-green-1: #0d130d;
  --accent-green-2: #131b13;
  --accent-green-3: #172c19;
  --accent-green-4: #193b1d;
  --accent-green-5: #204925;
  --accent-green-6: #28582e;
  --accent-green-7: #316838;
  --accent-green-8: #397c41;
  --accent-green-9: #60e273;
  --accent-green-10: #54d769;
  --accent-green-11: #53d668;
  --accent-green-12: #b7f2ba;
  --accent-green-a1: #00bb0003;
  --accent-green-a2: #40f9400b;
  --accent-green-a3: #46ff581d;
  --accent-green-a4: #3fff552d;
  --accent-green-a5: #51ff663c;
  --accent-green-a6: #5efd724d;
  --accent-green-a7: #68fd7b5e;
  --accent-green-a8: #6aff7c73;
  --accent-green-a9: #6bff81e0;
  --accent-green-a10: #61fe7ad5;
  --accent-green-a11: #60fe7ad4;
  --accent-green-a12: #c0fec3f2;
  --accent-green-contrast: #142716;
  --accent-green-surface: #15251580;
  --accent-green-indicator: #60e273;
  --accent-green-track: #60e273;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    .dark, .dark-theme {
      --accent-green-1: #0d130d;
      --accent-green-2: #131b14;
      --accent-green-3: #172c19;
      --accent-green-4: #193b1d;
      --accent-green-5: #1f4925;
      --accent-green-6: #28582e;
      --accent-green-7: #316838;
      --accent-green-8: #397c41;
      --accent-green-9: #60e273;
      --accent-green-10: #54d769;
      --accent-green-11: #53d668;
      --accent-green-12: #b7f2ba;
      --accent-green-a1: #00ea3d02;
      --accent-green-a2: #00fd390b;
      --accent-green-a3: #34ff5f1c;
      --accent-green-a4: #31ff592c;
      --accent-green-a5: #53ff6e3b;
      --accent-green-a6: #5fff784b;
      --accent-green-a7: #6fff835c;
      --accent-green-a8: #75ff8471;
      --accent-green-a9: #76ff89dd;
      --accent-green-a10: #6fff83d1;
      --accent-green-a11: #6fff83d0;
      --accent-green-a12: #c5ffc9ef;
      --accent-green-contrast: #142716;
      --accent-green-surface: #14241480;
      --accent-green-indicator: #60e273;
      --accent-green-track: #60e273;
    }

    @supports (color: lab(0% 0 0)) {
      .dark, .dark-theme {
        --accent-green-1: lab(5.23945% -3.06974 2.19414);
        --accent-green-2: lab(8.70893% -5.08327 3.66095);
        --accent-green-3: lab(15.8724% -12.5706 9.57599);
        --accent-green-4: lab(21.7025% -18.9081 14.624);
        --accent-green-5: lab(27.2891% -22.0647 17.087);
        --accent-green-6: lab(33.0739% -24.3477 18.8156);
        --accent-green-7: lab(39.3691% -27.7896 21.4944);
        --accent-green-8: lab(46.523% -32.3885 25.1147);
        --accent-green-9: lab(80.8624% -54.5335 42.6003);
        --accent-green-10: lab(77.0259% -54.5752 42.8095);
        --accent-green-11: lab(76.7933% -54.5779 42.8231);
        --accent-green-12: lab(90.3282% -27.3515 20.4997);
        --accent-green-indicator: lab(80.8624% -54.5335 42.6003);
        --accent-green-track: lab(80.8624% -54.5335 42.6003);
      }
    }

    @supports (color: color(display-p3 0 0 0)) {
      .dark, .dark-theme {
        --accent-green-a1: color(display-p3 0 .941 0 / .009);
        --accent-green-a2: color(display-p3 .435 .98 .341 / .043);
        --accent-green-a3: color(display-p3 .459 .996 .424 / .11);
        --accent-green-a4: color(display-p3 .455 .996 .408 / .173);
        --accent-green-a5: color(display-p3 .51 1 .475 / .232);
        --accent-green-a6: color(display-p3 .533 1 .506 / .295);
        --accent-green-a7: color(display-p3 .569 1 .545 / .362);
        --accent-green-a8: color(display-p3 .584 1 .549 / .442);
        --accent-green-a9: color(display-p3 .588 1 .565 / .866);
        --accent-green-a10: color(display-p3 .569 1 .545 / .82);
        --accent-green-a11: color(display-p3 .569 1 .545 / .816);
        --accent-green-a12: color(display-p3 .812 1 .796 / .937);
        --accent-green-surface: color(display-p3 .094 .141 .086 / .5);
      }
    }
  }
}

.dark, .dark-theme {
  --accent-red-1: #150f0f;
  --accent-red-2: #1d1514;
  --accent-red-3: #361818;
  --accent-red-4: #491a1c;
  --accent-red-5: #582224;
  --accent-red-6: #682e30;
  --accent-red-7: #803e3f;
  --accent-red-8: #a75253;
  --accent-red-9: #ff9797;
  --accent-red-10: #f38c8d;
  --accent-red-11: #ff9797;
  --accent-red-12: #fad5d4;
  --accent-red-a1: #de000005;
  --accent-red-a2: #fd604c0d;
  --accent-red-a3: #fd3e3e28;
  --accent-red-a4: #ff38403c;
  --accent-red-a5: #fd4a504d;
  --accent-red-a6: #fd60655e;
  --accent-red-a7: #ff727477;
  --accent-red-a8: #ff787aa1;
  --accent-red-a9: #ff9797;
  --accent-red-a10: #fe9293f3;
  --accent-red-a11: #ff9797;
  --accent-red-a12: #ffd9d8fa;
  --accent-red-contrast: #fff;
  --accent-red-surface: #29191780;
  --accent-red-indicator: #ff9797;
  --accent-red-track: #ff9797;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    .dark, .dark-theme {
      --accent-red-1: #150f0f;
      --accent-red-2: #1d1514;
      --accent-red-3: #361818;
      --accent-red-4: #491a1c;
      --accent-red-5: #582224;
      --accent-red-6: #682e30;
      --accent-red-7: #803e3f;
      --accent-red-8: #a85254;
      --accent-red-9: #ff9797;
      --accent-red-10: #f38c8d;
      --accent-red-11: #ff9797;
      --accent-red-12: #fad5d4;
      --accent-red-a1: #ff0e0d03;
      --accent-red-a2: #ff68550c;
      --accent-red-a3: #ff534f23;
      --accent-red-a4: #ff4c4e36;
      --accent-red-a5: #ff5d6045;
      --accent-red-a6: #ff707356;
      --accent-red-a7: #ff83846d;
      --accent-red-a8: #ff898895;
      --accent-red-a9: #ffa5a4f0;
      --accent-red-a10: #ffa1a1e3;
      --accent-red-a11: #ffa5a4f0;
      --accent-red-a12: #ffdfdff3;
      --accent-red-contrast: #fff;
      --accent-red-surface: #28171880;
      --accent-red-indicator: #ff9797;
      --accent-red-track: #ff9797;
    }

    @supports (color: lab(0% 0 0)) {
      .dark, .dark-theme {
        --accent-red-1: lab(5.02029% 2.40921 .966468);
        --accent-red-2: lab(7.64427% 4.42021 1.78184);
        --accent-red-3: lab(12.6205% 15.3187 6.90268);
        --accent-red-4: lab(16.8997% 22.7021 10.4631);
        --accent-red-5: lab(21.6785% 25.5765 11.7671);
        --accent-red-6: lab(27.4402% 26.8358 12.2329);
        --accent-red-7: lab(35.1319% 29.3758 13.3081);
        --accent-red-8: lab(45.9339% 36.0848 16.3661);
        --accent-red-9: lab(73.7775% 39.8419 17.7452);
        --accent-red-10: lab(69.8317% 39.8536 17.7951);
        --accent-red-11: lab(73.7775% 39.8419 17.7452);
        --accent-red-12: lab(88.4525% 12.9972 5.60293);
        --accent-red-indicator: lab(73.7775% 39.8419 17.7452);
        --accent-red-track: lab(73.7775% 39.8419 17.7452);
      }
    }

    @supports (color: color(display-p3 0 0 0)) {
      .dark, .dark-theme {
        --accent-red-a1: color(display-p3 .973 0 0 / .013);
        --accent-red-a2: color(display-p3 .988 .404 .318 / .047);
        --accent-red-a3: color(display-p3 1 .322 .294 / .139);
        --accent-red-a4: color(display-p3 .996 .29 .29 / .211);
        --accent-red-a5: color(display-p3 1 .361 .361 / .269);
        --accent-red-a6: color(display-p3 1 .439 .439 / .337);
        --accent-red-a7: color(display-p3 1 .514 .506 / .429);
        --accent-red-a8: color(display-p3 1 .537 .525 / .585);
        --accent-red-a9: color(display-p3 1 .647 .635 / .942);
        --accent-red-a10: color(display-p3 1 .631 .624 / .891);
        --accent-red-a11: color(display-p3 1 .647 .635 / .942);
        --accent-red-a12: color(display-p3 1 .875 .871 / .954);
        --accent-red-surface: color(display-p3 .149 .094 .094 / .5);
      }
    }
  }
}

.dark, .dark-theme {
  --color-background: #111;
}

:-webkit-any(.dark, .dark-theme) :where(html:not(.light, .light-theme)) {
  --color-background: #111;
}

:is(.dark, .dark-theme) :where(html:not(.light, .light-theme)) {
  --color-background: #111;
}

.dark, .dark-theme {
  --gray-1: #111;
  --gray-2: #181818;
  --gray-3: #222;
  --gray-4: #292929;
  --gray-5: #303030;
  --gray-6: #3a3a3a;
  --gray-7: #474747;
  --gray-8: #606060;
  --gray-9: #6d6d6d;
  --gray-10: #7a7a7a;
  --gray-11: #b3b3b3;
  --gray-12: #ededed;
  --gray-a1: #0000;
  --gray-a2: #ffffff08;
  --gray-a3: #ffffff12;
  --gray-a4: #ffffff1a;
  --gray-a5: #ffffff21;
  --gray-a6: #ffffff2c;
  --gray-a7: #ffffff3a;
  --gray-a8: #fff5;
  --gray-a9: #ffffff63;
  --gray-a10: #ffffff71;
  --gray-a11: #ffffffae;
  --gray-a12: #ffffffec;
  --gray-contrast: #fff;
  --gray-surface: #0000000d;
  --gray-indicator: #6d6d6d;
  --gray-track: #6d6d6d;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    .dark, .dark-theme {
      --gray-1: #111;
      --gray-2: #181818;
      --gray-3: #222;
      --gray-4: #292929;
      --gray-5: #303030;
      --gray-6: #3a3a3a;
      --gray-7: #474747;
      --gray-8: #606060;
      --gray-9: #6c6c6c;
      --gray-10: #7a7a7a;
      --gray-11: #b3b3b3;
      --gray-12: #ededed;
      --gray-a1: #0000;
      --gray-a2: #ffffff07;
      --gray-a3: #ffffff12;
      --gray-a4: #ffffff1a;
      --gray-a5: #ffffff21;
      --gray-a6: #ffffff2c;
      --gray-a7: #ffffff3a;
      --gray-a8: #fff5;
      --gray-a9: #ffffff63;
      --gray-a10: #ffffff70;
      --gray-a11: #ffffffae;
      --gray-a12: #ffffffec;
      --gray-contrast: #fff;
      --gray-surface: #0000000d;
      --gray-indicator: #6c6c6c;
      --gray-track: #6c6c6c;
    }

    @supports (color: lab(0% 0 0)) {
      .dark, .dark-theme {
        --gray-1: lab(5.09437% -.00000745058 0);
        --gray-2: lab(8.476% 0 0);
        --gray-3: lab(13.116% 0 0);
        --gray-4: lab(16.596% 0 0);
        --gray-5: lab(20.076% -.0000149012 0);
        --gray-6: lab(24.252% 0 0);
        --gray-7: lab(30.284% 0 0);
        --gray-8: lab(40.608% -.0000149012 0);
        --gray-9: lab(45.828% 0 0);
        --gray-10: lab(51.28% -.0000298023 0);
        --gray-11: lab(72.856% -.0000298023 0);
        --gray-12: lab(93.852% .0000298023 -.0000119209);
        --gray-indicator: lab(45.828% 0 0);
        --gray-track: lab(45.828% 0 0);
      }
    }

    @supports (color: color(display-p3 0 0 0)) {
      .dark, .dark-theme {
        --gray-a1: color(display-p3 0 0 0 / 0);
        --gray-a2: color(display-p3 1 1 1 / .029);
        --gray-a3: color(display-p3 1 1 1 / .071);
        --gray-a4: color(display-p3 1 1 1 / .101);
        --gray-a5: color(display-p3 1 1 1 / .13);
        --gray-a6: color(display-p3 1 1 1 / .172);
        --gray-a7: color(display-p3 1 1 1 / .227);
        --gray-a8: color(display-p3 1 1 1 / .332);
        --gray-a9: color(display-p3 1 1 1 / .387);
        --gray-a10: color(display-p3 1 1 1 / .441);
        --gray-a11: color(display-p3 1 1 1 / .681);
        --gray-a12: color(display-p3 1 1 1 / .924);
        --gray-surface: color(display-p3 0 0 0 / .05);
      }
    }
  }
}

@keyframes enter {
  0% {
    opacity: var(--enter-opacity, 1);
    transform: translate3d(var(--enter-translate-x, 0), var(--enter-translate-y, 0), 0) scale3d(var(--enter-scale, 1), var(--enter-scale, 1), var(--enter-scale, 1)) rotate(var(--enter-rotate, 0));
  }
}

@keyframes exit {
  100% {
    opacity: var(--exit-opacity, 1);
    transform: translate3d(var(--exit-translate-x, 0), var(--exit-translate-y, 0), 0) scale3d(var(--exit-scale, 1), var(--exit-scale, 1), var(--exit-scale, 1)) rotate(var(--exit-rotate, 0));
  }
}

.transition-colors {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke;
  transition-timing-function: var(--ease);
  transition-duration: .15s;
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: var(--ease);
  transition-duration: .15s;
}

.transition-transform {
  transition-property: scale, transform;
  transition-timing-function: var(--ease);
  transition-duration: .15s;
}

.rotate-180 {
  --rotate: 180deg;
  transform: rotate(var(--rotate));
}

@custom-media --screen-xs (width> = 520px);

@custom-media --screen-sm (width> = 768px);

@custom-media --screen-md (width> = 1024px);

@custom-media --screen-lg (width> = 1280px);

@custom-media --screen-xl (width> = 1640px);

:where(html) {
  --accent-green-3: #a5e7af;
  --accent-green-4: #58ce6a;
}

:where(.dark) {
  --accent-green-3: #132d21;
  --accent-green-4: #113b29;
}

.bg-gray-3 {
  background-color: var(--ui-background);
}

.bg-positive {
  background-color: var(--ui-positive);
}

.bg-neutral {
  background-color: var(--ui-neutral);
}

.bg-negative {
  background-color: var(--ui-negative);
}

.bg-positive-muted {
  background-color: var(--ui-positive-muted);
}

.bg-neutral-muted {
  background-color: var(--ui-neutral-muted);
}

.bg-negative-muted {
  background-color: var(--ui-negative-muted);
}

.text-subtle {
  color: var(--text-subtle);
}

:where(html) {
  --radius-1: .125rem;
  --radius-2: .25rem;
  --radius-3: .375rem;
  --radius-4: .5rem;
  --radius-5: .75rem;
  --radius-6: 1rem;
  --radius-8: 1.5rem;
  --radius-circle: 50%;
  --radius-pill: 9999px;
}

.radius-1 {
  border-radius: var(--radius-1);
}

.rounded-md {
  border-radius: calc(var(--radius-2)  - 2px);
}

.rounded-lg {
  border-radius: calc(1rem - 2px);
}

.radius-circle {
  border-radius: var(--radius-circle);
}

:where(html) {
  --shadow-1: inset 0 0 0 1px var(--gray-a5), inset 0 1.5px 2px 0 var(--gray-a2), inset 0 1.5px 2px 0 var(--black-a2);
  --shadow-2: 0 0 0 1px var(--gray-a3), 0 0 0 .5px var(--black-a1), 0 1px 1px 0 var(--gray-a2), 0 2px 1px -1px var(--black-a1), 0 1px 3px 0 var(--black-a1);
  --shadow-3: 0 0 0 1px var(--gray-a3), 0 2px 3px -2px var(--gray-a3), 0 3px 12px -4px var(--black-a2), 0 4px 16px -8px var(--black-a2);
  --shadow-4: 0 0 0 1px var(--gray-a3), 0 8px 40px var(--black-a1), 0 12px 32px -16px var(--gray-a3);
  --shadow-5: 0 0 0 1px var(--gray-a3), 0 12px 60px var(--black-a3), 0 12px 32px -16px var(--gray-a5);
  --shadow-6: 0 0 0 1px var(--gray-a3), 0 12px 60px var(--black-a3), 0 16px 64px var(--gray-a2), 0 16px 36px -20px var(--gray-a7);
}

@supports (color: color-mix(in oklab, white, black)) {
  :where(html) {
    --shadow-1: inset 0 0 0 1px var(--gray-a5), inset 0 1.5px 2px 0 var(--gray-a2), inset 0 1.5px 2px 0 var(--black-a2);
    --shadow-2: 0 0 0 1px color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%), 0 0 0 .5px var(--black-a1), 0 1px 1px 0 var(--gray-a2), 0 2px 1px -1px var(--black-a1), 0 1px 3px 0 var(--black-a1);
    --shadow-3: 0 0 0 1px color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%), 0 2px 3px -2px var(--gray-a3), 0 3px 12px -4px var(--black-a2), 0 4px 16px -8px var(--black-a2);
    --shadow-4: 0 0 0 1px color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%), 0 8px 40px var(--black-a1), 0 12px 32px -16px var(--gray-a3);
    --shadow-5: 0 0 0 1px color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%), 0 12px 60px var(--black-a3), 0 12px 32px -16px var(--gray-a5);
    --shadow-6: 0 0 0 1px color-mix(in oklab, var(--gray-a3), var(--gray-3) 25%), 0 12px 60px var(--black-a3), 0 16px 64px var(--gray-a2), 0 16px 36px -20px var(--gray-a7);
  }
}

:-webkit-any(.dark, .dark-theme) {
  --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
  --shadow-2: 0 0 0 1px var(--gray-a6), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --shadow-3: 0 0 0 1px var(--gray-a6), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
  --shadow-4: 0 0 0 1px var(--gray-a6), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
  --shadow-5: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
  --shadow-6: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
}

:is(.dark, .dark-theme) {
  --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
  --shadow-2: 0 0 0 1px var(--gray-a6), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --shadow-3: 0 0 0 1px var(--gray-a6), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
  --shadow-4: 0 0 0 1px var(--gray-a6), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
  --shadow-5: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
  --shadow-6: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
}

:-webkit-any(.dark, .dark-theme) :where(html:not(.light, .light-theme)) {
  --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
  --shadow-2: 0 0 0 1px var(--gray-a6), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --shadow-3: 0 0 0 1px var(--gray-a6), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
  --shadow-4: 0 0 0 1px var(--gray-a6), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
  --shadow-5: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
  --shadow-6: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
}

:is(.dark, .dark-theme) :where(html:not(.light, .light-theme)) {
  --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
  --shadow-2: 0 0 0 1px var(--gray-a6), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
  --shadow-3: 0 0 0 1px var(--gray-a6), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
  --shadow-4: 0 0 0 1px var(--gray-a6), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
  --shadow-5: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
  --shadow-6: 0 0 0 1px var(--gray-a6), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
}

@supports (color: color-mix(in oklab, white, black)) {
  :-webkit-any(.dark, .dark-theme) {
    --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
    --shadow-2: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
    --shadow-3: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
    --shadow-4: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
    --shadow-5: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
    --shadow-6: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
  }

  :is(.dark, .dark-theme) {
    --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
    --shadow-2: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
    --shadow-3: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
    --shadow-4: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
    --shadow-5: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
    --shadow-6: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
  }

  :-webkit-any(.dark, .dark-theme) :where(html:not(.light, .light-theme)) {
    --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
    --shadow-2: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
    --shadow-3: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
    --shadow-4: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
    --shadow-5: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
    --shadow-6: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
  }

  :is(.dark, .dark-theme) :where(html:not(.light, .light-theme)) {
    --shadow-1: inset 0 -1px 1px 0 var(--gray-a3), inset 0 0 0 1px var(--gray-a3), inset 0 3px 4px 0 var(--black-a5), inset 0 0 0 1px var(--gray-a4);
    --shadow-2: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 0 0 .5px var(--black-a3), 0 1px 1px 0 var(--black-a6), 0 2px 1px -1px var(--black-a6), 0 1px 3px 0 var(--black-a5);
    --shadow-3: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 2px 3px -2px var(--black-a3), 0 3px 8px -2px var(--black-a6), 0 4px 12px -4px var(--black-a7);
    --shadow-4: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 8px 40px var(--black-a3), 0 12px 32px -16px var(--black-a5);
    --shadow-5: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a5), 0 12px 32px -16px var(--black-a7);
    --shadow-6: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%), 0 12px 60px var(--black-a4), 0 16px 64px var(--black-a6), 0 16px 36px -20px var(--black-a11);
  }
}

:where(html) {
  --size-1: .25rem;
  --size-2: .5rem;
  --size-3: .75rem;
  --size-4: 1rem;
  --size-5: 1.25rem;
  --size-6: 1.5rem;
  --size-7: 1.75rem;
  --size-8: 2rem;
  --size-9: 2.25rem;
  --size-10: 2.5rem;
}

.w-5 {
  width: var(--size-5);
}

:where(html) {
  --space-1: .25rem;
  --space-2: .5rem;
  --space-3: .75rem;
  --space-4: 1rem;
  --space-5: 1.5rem;
  --space-6: 2rem;
  --space-7: 2.5rem;
  --space-8: 3rem;
  --space-9: 4rem;
}

:where(html) {
  --font-size-1: .75rem;
  --font-size-2: .875rem;
  --font-size-3: 1rem;
  --font-size-4: 1.125rem;
  --font-size-5: 1.25rem;
  --font-size-6: 1.5rem;
  --font-size-7: 1.75rem;
  --font-size-8: 2.1875rem;
  --font-size-9: 3.75rem;
  --font-weight-extralight: 200;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;
  --line-height-1: 1;
  --line-height-2: 1.25;
  --line-height-3: 1.5;
  --line-height-4: 1.625;
  --line-height-5: 1.75;
  --line-height-6: 1.875;
  --line-height-7: 2.25;
  --line-height-8: 2.5;
  --line-height-9: 3.75;
  --letter-spacing-1: .0025em;
  --letter-spacing-2: 0em;
  --letter-spacing-3: 0em;
  --letter-spacing-4: -.0025em;
  --letter-spacing-5: -.005em;
  --letter-spacing-6: -.00625em;
  --letter-spacing-7: -.0075em;
  --letter-spacing-8: -.01em;
  --letter-spacing-9: -.025em;
}

.headline-lg {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  margin: 0;
  line-height: normal;
}

.headline-md {
  font-size: var(--font-size-3);
  font-style: normal;
  font-weight: var(--font-weight-bold);
  letter-spacing: .1rem;
  text-transform: uppercase;
  margin: 0;
  line-height: normal;
}

.headline-sm {
  font-size: var(--font-size-1);
  font-style: normal;
  font-weight: var(--font-weight-bold);
  letter-spacing: .075rem;
  text-transform: uppercase;
  margin: 0;
  line-height: normal;
}

.text-md {
  font-size: var(--space-4);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  margin: 0;
  line-height: normal;
}

.text-sm {
  font-size: var(--font-size-2);
  margin: 0;
  font-style: normal;
  line-height: normal;
}

.text-xs {
  font-size: var(--font-size-1);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: normal;
}

.text-xs-md {
  font-size: var(--font-size-1);
  font-style: normal;
  font-weight: var(--font-weight-medium);
  line-height: normal;
}

.text-regular {
  font-weight: var(--font-weight-regular);
}

.text-medium {
  font-weight: var(--font-weight-medium);
}

.text-semibold {
  font-weight: var(--font-weight-semibold);
}

.text-bold {
  font-weight: var(--font-weight-bold);
}

.text-extrabold {
  font-weight: var(--font-weight-extrabold);
}

.text-1 {
  font-size: var(--font-size-1);
  letter-spacing: var(--letter-spacing-1);
  line-height: var(--line-height-2);
  text-wrap: pretty;
}

.text-2 {
  font-size: var(--font-size-2);
  letter-spacing: var(--letter-spacing-2);
  line-height: var(--line-height-2);
  text-wrap: pretty;
}

.text-3 {
  font-size: var(--font-size-3);
  letter-spacing: var(--letter-spacing-3);
  line-height: var(--line-height-3);
  text-wrap: pretty;
}

.text-4 {
  font-size: var(--font-size-4);
  letter-spacing: var(--letter-spacing-4);
  line-height: var(--line-height-4);
  text-wrap: pretty;
}

.text-5 {
  font-size: var(--font-size-5);
  letter-spacing: var(--letter-spacing-5);
  line-height: var(--line-height-5);
  text-wrap: pretty;
}

.text-6 {
  font-size: var(--font-size-6);
  letter-spacing: var(--letter-spacing-6);
  line-height: var(--line-height-6);
  text-wrap: pretty;
}

.text-7 {
  font-size: var(--font-size-7);
  letter-spacing: var(--letter-spacing-7);
  line-height: var(--line-height-7);
  text-wrap: pretty;
}

.text-8 {
  font-size: var(--font-size-8);
  letter-spacing: var(--letter-spacing-8);
  line-height: var(--line-height-8);
  text-wrap: pretty;
}

.leading-none {
  line-height: 1;
}

html, body {
  height: 100%;
}

html {
  box-sizing: border-box;
  line-height: 1.15;
}

*, :before, :after {
  box-sizing: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
  }
}

::selection {
  background-color: var(--accent-9);
  color: var(--color-white);
}

body {
  background-color: var(--color-background);
  color: var(--text);
  margin: 0;
  font-family: Inter, sans-serif;
}

[tabindex], a, button, input, label, select, textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

a {
  color: currentColor;
  outline: none;
  text-decoration: none;
}

a * {
  pointer-events: none;
}

strong {
  font-weight: var(--font-weight-bold);
}

svg:not([fill]) {
  fill: currentColor;
}

svg[aria-hidden="true"] {
  flex-shrink: 0;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

dl {
  margin: 0;
}

h1, h2, h3, h4, p {
  font-size: 100%;
  font-weight: 400;
  line-height: inherit;
  margin: 0;
}

p a {
  text-decoration: underline;
}

button, input, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button {
  cursor: pointer;
  font-weight: inherit;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border: none;
  outline: none;
  padding: 0;
}

button[disabled] {
  cursor: default;
}

a *, button * {
  pointer-events: none;
}

label {
  cursor: pointer;
}

input[type="search"] {
  -webkit-appearance: none;
  appearance: none;
}

input[type="search"]::-webkit-search-decoration {
  display: none;
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

input[type="search"]::-webkit-search-results-button {
  display: none;
}

input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

p + p {
  margin-top: 20px;
}

.prose > p + p {
  margin-top: 1lh;
}

.prose > p + h3 {
  margin-top: 1lh;
}

.prose > ol + p {
  margin-top: 1lh;
}

.prose > ul + p {
  margin-top: 1lh;
}

.prose > ol + h3 {
  margin-top: 1lh;
}

.prose > ul + h3 {
  margin-top: 1lh;
}

.prose > ol + h4 {
  margin-top: 1lh;
}

.prose > ul + h4 {
  margin-top: 1lh;
}

.prose > h3 + ol {
  margin-top: .25lh;
}

.prose > h3 + ul {
  margin-top: .25lh;
}

.prose > h3 + p {
  margin-top: .25lh;
}

.prose > h4 + ol {
  margin-top: .25lh;
}

.prose > h4 + ul {
  margin-top: .25lh;
}

.prose > h4 + p {
  margin-top: .25lh;
}

.prose ol {
  list-style: unset;
  margin: unset;
  padding: unset;
  margin-left: 1.5em;
}

.prose ul {
  list-style: unset;
  margin: unset;
  padding: unset;
  margin-left: 1.5em;
}

.prose li li {
  margin-top: 0;
}

.prose h3 {
  font-size: var(--font-size-4);
  letter-spacing: var(--letter-spacing-4);
  line-height: var(--line-height-4);
  font-weight: var(--font-weight-semibold);
  text-wrap: pretty;
}

.prose h4 {
  font-size: var(--font-size-3);
  letter-spacing: var(--letter-spacing-3);
  line-height: var(--line-height-3);
  font-weight: var(--font-weight-semibold);
  text-wrap: pretty;
}

.visuallyHidden {
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.link, .prose a, .react-aria-Link {
  color: var(--accent-blue-11);
  align-items: center;
  gap: var(--space-1);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  text-underline-offset: calc(.025em + 2px);
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
  -webkit-text-decoration-style: solid;
  text-decoration-style: solid;
  text-decoration-thickness: min(2px, max(1px, .05em));
  -webkit-text-decoration-color: var(--accent-blue-a5);
  text-decoration-color: var(--accent-blue-a5);
  line-height: normal;
  display: inline-flex;
}

:is(.link, .prose a, .react-aria-Link):hover {
  color: var(--accent-blue-12);
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

@supports (color: color-mix(in oklab, white, black)) {
  .link, .prose a, .react-aria-Link {
    -webkit-text-decoration-color: color-mix(in oklab, var(--accent-blue-a5), var(--gray-a6));
    text-decoration-color: color-mix(in oklab, var(--accent-blue-a5), var(--gray-a6));
  }
}

.bi-container {
  font-synthesis-weight: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --container-padding: clamp(1rem, .0769rem + 3.0769vi, 3rem);
  padding-inline: var(--container-padding);
  background-color: var(--app-background);
  color: var(--text-color);
  min-height: calc(100vh - var(--size-header-height));
  margin-left: -20px;
  margin-right: -20px;
  position: relative;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.hidden {
  display: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.tabular-nums {
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.uppercase {
  text-transform: uppercase;
}

.w-15p {
  width: 15%;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.color-positive {
  color: var(--teal-a11);
}

.color-negative {
  color: var(--red-a11);
}

.headroom-wrapper {
  z-index: 3;
  width: 100%;
  max-height: 80px;
  position: relative;
}

.headroom {
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
}

.headroom--unfixed {
  opacity: 1;
  position: relative;
  transform: none;
}

.headroom--scrolled {
  transition: opacity .2s ease-in-out, transform .2s ease-in-out;
}

.headroom--unpinned {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  transform: translateY(-100%);
}

.headroom--pinned {
  opacity: 1;
  position: fixed;
  transform: none;
}

.rdp-root {
  --rdp-accent-color: blue;
  --rdp-accent-background-color: var(--gray-a3);
  --rdp-font-family: system-ui;
  --rdp-day-font: inherit;
  --rdp-day-height: 2.75rem;
  --rdp-day-width: 2.75rem;
  --rdp-day_button-border-radius: var(--radius-3);
  --rdp-day_button-border: 2px solid transparent;
  --rdp-day_button-height: var(--rdp-day-width);
  --rdp-day_button-width: var(--rdp-day-height);
  --rdp-selected-border: 2px solid var(--rdp-accent-color);
  --rdp-selected-font: bold large var(--rdp-font-family);
  --rdp-disabled-opacity: .5;
  --rdp-outside-opacity: .75;
  --rdp-today-color: var(--rdp-accent-color);
  --rdp-dropdown-gap: .5rem;
  --rdp-month_caption-font: bold larger var(--rdp-font-family);
  --rdp-months-gap: 2rem;
  --rdp-nav_button-disabled-opacity: .5;
  --rdp-nav_button-height: 2.25rem;
  --rdp-nav_button-width: 2.25rem;
  --rdp-nav-height: 2.75rem;
  --rdp-range_middle-background-color: var(--rdp-accent-background-color);
  --rdp-range_middle-font: normal medium var(--rdp-font-family);
  --rdp-range_middle-foreground-color: white;
  --rdp-range_middle-color: inherit;
  --rdp-range_start-color: white;
  --rdp-range_start-background: linear-gradient(var(--rdp-gradient-direction), transparent 50%, var(--rdp-range_middle-background-color) 50%);
  --rdp-range_start-date-background-color: var(--rdp-accent-color);
  --rdp-range_end-background: linear-gradient(var(--rdp-gradient-direction), var(--rdp-range_middle-background-color) 50%, transparent 50%);
  --rdp-range_end-color: white;
  --rdp-range_end-date-background-color: var(--rdp-accent-color);
  --rdp-week_number-border-radius: 100%;
  --rdp-week_number-border: 2px solid transparent;
  --rdp-week_number-font: 400 small var(--rdp-font-family);
  --rdp-week_number-height: var(--rdp-day-height);
  --rdp-week_number-opacity: .75;
  --rdp-week_number-width: var(--rdp-day-width);
  --rdp-weeknumber-text-align: center;
  --rdp-weekday-font: 500 smaller var(--rdp-font-family);
  --rdp-weekday-opacity: .75;
  --rdp-weekday-padding: .5rem 0rem;
  --rdp-weekday-text-align: center;
  --rdp-gradient-direction: 90deg;
}

.rdp-root[dir="rtl"] {
  --rdp-gradient-direction: -90deg;
}

.rdp-root {
  position: relative;
}

.rdp-day {
  width: var(--rdp-day-width);
  height: var(--rdp-day-height);
  font: var(--rdp-day-font);
  text-align: center;
}

.rdp-day_button {
  width: var(--rdp-day_button-width);
  height: var(--rdp-day_button-height);
  border: var(--rdp-day_button-border);
  border-radius: var(--rdp-day_button-border-radius);
  justify-content: center;
  align-items: center;
  display: flex;
}

.rdp-day_button:disabled {
  cursor: revert;
}

.rdp-caption_label {
  z-index: 1;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  border: 0;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.rdp-button_next, .rdp-button_previous {
  -webkit-appearance: none;
  appearance: none;
  font: inherit;
  color: inherit;
  cursor: pointer;
  width: var(--rdp-nav_button-width);
  height: var(--rdp-nav_button-height);
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: inline-flex;
  position: relative;
}

.rdp-button_next:disabled, .rdp-button_previous:disabled {
  cursor: revert;
  opacity: var(--rdp-nav_button-disabled-opacity);
}

.rdp-chevron {
  fill: var(--rdp-accent-color);
  display: inline-block;
}

.rdp-root[dir="rtl"] .rdp-nav .rdp-chevron {
  transform-origin: 50%;
  transform: rotate(180deg);
}

.rdp-dropdowns {
  align-items: center;
  gap: var(--rdp-dropdown-gap);
  display: inline-flex;
  position: relative;
}

.rdp-dropdown {
  z-index: 2;
  opacity: 0;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: inherit;
  line-height: inherit;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
}

.rdp-dropdown:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 0;
}

.rdp-dropdown:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.rdp-dropdown:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.rdp-dropdown_root {
  align-items: center;
  display: inline-flex;
  position: relative;
}

.rdp-dropdown_root[data-disabled="true"] .rdp-chevron {
  opacity: var(--rdp-disabled-opacity);
}

.rdp-month_caption {
  height: var(--rdp-nav-height);
  font: var(--rdp-month_caption-font);
  align-content: center;
  display: flex;
}

.rdp-months {
  gap: var(--rdp-months-gap);
  flex-wrap: wrap;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  min-height: 347px;
  display: flex;
  position: relative;
}

.rdp-month_grid {
  border-collapse: collapse;
}

.rdp-nav {
  height: var(--rdp-nav-height);
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
}

.rdp-nav:not(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.rdp-nav:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 0;
}

.rdp-nav:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: 0;
}

.rdp-weekday {
  opacity: var(--rdp-weekday-opacity);
  padding: var(--rdp-weekday-padding);
  font: var(--rdp-weekday-font);
  text-align: var(--rdp-weekday-text-align);
  text-transform: var(--rdp-weekday-text-transform);
  -webkit-user-select: none;
  user-select: none;
}

.rdp-week_number {
  opacity: var(--rdp-week_number-opacity);
  font: var(--rdp-week_number-font);
  height: var(--rdp-week_number-height);
  width: var(--rdp-week_number-width);
  border: var(--rdp-week_number-border);
  border-radius: var(--rdp-week_number-border-radius);
  text-align: var(--rdp-weeknumber-text-align);
}

.rdp-today:not(.rdp-outside) {
  color: var(--rdp-today-color);
}

.rdp-selected {
  font: var(--rdp-selected-font);
}

.rdp-selected .rdp-day_button {
  border: var(--rdp-selected-border);
}

.rdp-outside {
  opacity: var(--rdp-outside-opacity);
}

.rdp-disabled {
  opacity: var(--rdp-disabled-opacity);
}

.rdp-hidden {
  visibility: hidden;
  color: var(--rdp-range_start-color);
}

.rdp-range_start {
  background: var(--rdp-range_start-background);
}

.rdp-range_start .rdp-day_button {
  background-color: var(--rdp-range_start-date-background-color);
  color: var(--rdp-range_start-color);
}

.rdp-range_middle {
  background-color: var(--rdp-range_middle-background-color);
  font: var(--rdp-range_middle-font);
}

.rdp-range_middle .rdp-day_button {
  border-color: #0000;
  border: unset;
  border-radius: unset;
  color: var(--rdp-range_middle-color);
}

.rdp-range_end {
  background: var(--rdp-range_end-background);
  color: var(--rdp-range_end-color);
}

.rdp-range_end .rdp-day_button {
  color: var(--rdp-range_start-color);
  background-color: var(--rdp-range_end-date-background-color);
}

.rdp-range_start.rdp-range_end {
  background: revert;
}

.rdp-focusable {
  cursor: pointer;
}

.rdp-root {
  --rdp-accent-color: var(--accent-9);
  --rdp-accent-background-color: var(--ui-background);
  --rdp-font-family: "Inter", sans-serif;
  --rdp-day-height: 40px;
  --rdp-day-width: 40px;
  --rdp-outside-opacity: .5;
  --rdp-day-font: var(--font-size-2) var(--rdp-font-family);
  --rdp-range_middle-font: var(--font-size-2) var(--rdp-font-family);
  --rdp-selected-font: var(--font-size-2) var(--rdp-font-family);
  --rdp-weekday-font: var(--font-size-1) var(--rdp-font-family);
  --rdp-month_caption-font: var(--font-size-3) var(--rdp-font-family);
  justify-content: center;
  display: flex;
}

.rdp-weekday {
  color: var(--text-color-secondary);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
}

.rdp-month_caption {
  justify-content: center;
  display: flex;
}

.rdp-nav {
  left: 0;
  right: 0;
}

.rdp-button_previous {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.rdp-button_next {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.rdp-week:not(:first-child) {
  border-top: var(--space-1) solid var(--gray-1);
}

.rdp-selected:not(.rdp-range_middle) .rdp-day_button {
  background-color: var(--rdp-range_start-date-background-color);
  color: var(--rdp-range_start-color);
}

.rdp-day_button[disabled], .rdp-disabled, .rdp-outside {
  color: var(--text-color-secondary);
  cursor: default;
  pointer-events: none;
  opacity: var(--rdp-outside-opacity);
}

.rdp-day:not(.rdp-disabled):not(.rdp-selected):not(.rdp-day_possible):not(.rdp-outside) .rdp-day_button:hover {
  background-color: var(--ui-background-hover);
}

:root {
  --color-blue: #081934;
  --color-grey-blue: #76889d;
  --color-red: #ff5c3e;
  --color-white: #fff;
  --color-light-blue: #488dff;
  --color-turquoise: #15bbcd;
  --color-promoter: #30b170;
  --color-passive: #f8b400;
  --color-detractor: #dd3636;
  --color-prioritize: #fc5c65;
  --color-maintain: #fd9644;
  --color-adapt: #45aaf2;
  --color-observe: #8854d0;
  --size-header-height: 80px;
  --size-max-width: 1500px;
  --size-min-width: 720px;
  --size-workspace-max-width: 2560px;
  --shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --ease: cubic-bezier(.4, 0, .2, 1);
  --back-inOut: linear(0 0%, -.0077 5.2%, -.0452 16.98%, -.0493 22.35%, -.0418 25.57%, -.0258 28.57%, -.0007 31.42%, .0335 34.15%, .1242 39.03%, .2505 43.65%, .3844 47.35%, .656 53.68%, .81 58.37%, .9282 63.52%, .9719 66.23%, 1.0055 69.04%, 1.0255 71.4%, 1.0396 73.87%, 1.0477 76.48%, 1.05 79.27%, 1.0419 84.36%, 1.0059 95.49%, 1 100%);
  --color-integration-awaiting: var(--color-turquoise);
  --color-integration-running: var(--accent-green-9);
  --color-comment-unread: var(--color-light-blue);
  --app-background: var(--gray-1);
  --background-subtle: var(--gray-2);
  --ui-background: var(--gray-3);
  --ui-background-hover: var(--gray-4);
  --ui-background-active: var(--gray-5);
  --border-subtle: var(--gray-6);
  --ui-border: var(--gray-7);
  --ui-border-hover: var(--gray-8);
  --ui-border-active: var(--gray-8);
  --text-subtle: var(--gray-11);
  --text: var(--gray-12);
  --color-destructive: var(--accent-red-9);
  --color-destructive-contrast: var(--accent-red-contrast);
  --focus-ring-color: var(--grass-8);
  --text-color: var(--gray-12);
  --text-color-secondary: var(--gray-11);
  --text-color-disabled: var(--gray-a8);
  --text-color-placeholder: var(--gray-a10);
  --border-color: var(--gray-7);
  --border-color-hover: var(--gray-8);
  --border-color-pressed: var(--gray-8);
  --border-color-disabled: var(--gray-a7);
  --field-background: var(--gray-surface);
  --text-field-selection-color: var(--accent-a5);
  --text-field-focus-color: var(--accent-8);
  --ui-positive: #58ce6a;
  --ui-neutral: #d5d5dc;
  --ui-negative: #ff5c3e;
  --ui-positive-muted: #e8fceb;
  --ui-neutral-muted: #f3f3f3;
  --ui-negative-muted: #ffc6c6;
}

